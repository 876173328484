import { ReportingPeriod } from "models/ReportingPeriod";
import { ReportingTerms } from "models/ReportingTerms";

export class ReportingTermValueConverter {
    toView(value: ReportingPeriod) {
        var _reportingTerms = new ReportingTerms(value.reportingFrequency);
        const terms = _reportingTerms.getReportingTerms()

        return terms.find(o => o.id === value.reportingTerm).description;
    }
}
