import { ReportStatus } from "models/ReportStatus";

export class ReportPlanStatusValueConverter {
    statusNames = [
        {
            id: ReportStatus.Draft,
            name: "<span style='color: red'>Plan Not Submitted</span>"
        },
        {
            id: ReportStatus.PlanPromoted,
            name: "<span style='color: green'>Plan Submitted</span>, <span style='color: red'>Report Not Submitted</span>"
        },
        {
            id: ReportStatus.Submitted,
            name: "<span style='color: green'>Plan Submitted</span>, <span style='color: green'>Report Submitted</span>"
        }
    ];

    toView(value: ReportStatus) {
        return this.statusNames.find(o => o.id === value).name;
    }
}
